<template>
  <transition
    :enter-from-class="`transform ${enterClass} ${fade ? 'opacity-0' : ''}`"
    :enter-to-class="`transform ${enterToClass} ${fade ? 'opacity-100' : ''}`"
    :leave-from-class="`transform ${leaveClass} ${fade ? 'opacity-100' : ''}`"
    :leave-to-class="`transform ${leaveToClass} ${fade ? 'opacity-0' : ''}`"
    :enter-active-class="`transition duration-${duration} ease-in-out`"
    :leave-active-class="`transition duration-${duration} ease-in-out`"
    :mode="mode"
    appear
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "out-in",
    },
    dir: {
      type: String,
      default: "left",
    },
    duration: {
      type: [String, Number],
      default: 150,
    },
    flow: {
      type: Boolean,
      default: false,
    },
    fade: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 2,
    },
  },
  computed: {
    enterClass() {
      switch (this.dir) {
        case "left":
          return `translate-x-${this.full ? "full" : this.size}`
        case "right":
          return `-translate-x-${this.full ? "full" : this.size}`
        case "up":
          return `translate-y-${this.full ? "full" : this.size}`
        case "down":
          return `-translate-y-${this.full ? "full" : this.size}`
        default:
          return ""
      }
    },
    enterToClass() {
      switch (this.dir) {
        case "left":
        case "right":
          return "translate-x-0"
        case "up":
        case "down":
          return "translate-y-0"
        default:
          return ""
      }
    },
    leaveClass() {
      switch (this.dir) {
        case "left":
        case "right":
          return "translate-x-0"
        case "up":
        case "down":
          return "translate-y-0"
        default:
          return ""
      }
    },
    leaveToClass() {
      switch (this.dir) {
        case "left":
          return this.flow
            ? `-translate-x-${this.full ? "full" : this.size}`
            : `translate-x-${this.full ? "full" : this.size}`
        case "right":
          return this.flow
            ? `translate-x-${this.full ? "full" : this.size}`
            : `-translate-x-${this.full ? "full" : this.size}`
        case "down":
          return this.flow
            ? `translate-y-${this.full ? "full" : this.size}`
            : `-translate-y-${this.full ? "full" : this.size}`
        case "up":
          return this.flow
            ? `translate-y-${this.full ? "full" : this.size}`
            : `-translate-y-${this.full ? "full" : this.size}`
        default:
          return ""
      }
    },
  },
}
</script>
